import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import buttonStyle from "../../../style/button.module.css"
import inputStyle from "../../../style/input.module.css"
import c from "capitalize"
import { uniqueId } from "lodash"
import { Banner } from "../../util/Banner"
import Select from "react-select"
import style from "../groups/GroupUpload.module.css"
import exportStyle from "./ExportEditor.module.css"
import { useDispatch } from "react-redux"
import { showError, showInfo } from "../../../redux/actions/NotificationActions"
import makeAnimated from "react-select/animated"
import { ExportErrorViewer } from "./ExportErrorViewer"
import { Toggle } from "../../util/input/Toggle"

const GET_ALL_PRICING_LISTS = gql`
    query pricingListsList {
        pricingLists(limit: -1) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                name: displayName
                chosenCalculation {
                    id
                }
                isCorrectionOf {
                    id
                }
                pricingListType {
                    id
                    pricingListTypeNumber
                }
            }
        }
        marketingLists(limit: -1) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                name
                chosenCalculation {
                    id
                }
            }
        }
        allocationLists(limit: -1) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                name
                chosenCalculation {
                    id
                }
            }
        }
    }
`

const EXPORT_PRICING_LISTS = gql`
    mutation (
        $ids: [UUID!]
        $targets: [TargetEnum]
        $exportAsCorrection: Boolean
        $marketingList: UUID
        $allocationList: UUID
        $minListIds: [UUID!]
        $minValidFrom: DateTime
        $minValidUntil: DateTime
    ) {
        exportPricingLists(
            pricingListsIds: $ids
            targets: $targets
            exportAsCorrection: $exportAsCorrection
            marketingListId: $marketingList
            allocationListId: $allocationList
            minListIds: $minListIds
            minValidFrom: $minValidFrom
            minValidUntil: $minValidUntil
        )
    }
`
/*
const GET_PRE_EXPORT_REPORT = gql`
    query pre_export($ids: [UUID!]) {
        pricingLists(ids: $ids, limit: -1) {
            page {
                name: displayName
                id
                chosenCalculation {
                    name
                    id
                    preExportIssues {
                        id
                        pzn
                        name
                        message
                    }
                    preExportReport {
                        canBeExported
                        exportWarning
                        totalResults
                        unresolvedIssues
                        lastPlausiCheck
                    }
                }
            }
        }
    }
`
*/

const OPTIONS_250 = [
    "201",
    "202",
    "203",
    "222",
    "205",
    "206",
    "225",
    "208",
    "209",
    "228",
]

const Display250 = ({ present }) => {
    if (!present?.length) {
        return <p />
    } else {
        return (
            <p className={style.list_250}>
                {OPTIONS_250.map((r) => (
                    <span
                        key={r}
                        style={{
                            backgroundColor:
                                present.filter((p) => p === r).length === 1
                                    ? "var(--green)"
                                    : present.filter((p) => p === r).length ===
                                      0
                                    ? "var(--red)"
                                    : "var(--orange)",
                        }}
                    >
                        {r}
                    </span>
                ))}
            </p>
        )
    }
}

export const ExportEditor = () => {
    const { t } = useTranslation(["common", "table"])
    const [outlook_export_button_id] = useState(() =>
        uniqueId("outlook_export_button_"),
    )
    const [pint_export_button_id] = useState(() =>
        uniqueId("pint_export_button_"),
    )

    const [csv_export_button_id] = useState(() =>
        uniqueId("csv_export_button_"),
    )
    const [dst_export_button_id] = useState(() =>
        uniqueId("dat_export_button_"),
    )
    const [use_correction] = useState(() => uniqueId("use_correction_"))
    const [min_valid_from_field] = useState(() => uniqueId("min_valid_from_"))
    const [min_valid_until_field] = useState(() => uniqueId("min_valid_until_"))
    const [pricingLists, setPricingLists] = useState([])
    const [minPricingLists, setMinPricingLists] = useState([])
    const [marketingList, setMarketingList] = useState("")
    const [allocationList, setAllocationList] = useState("")
    const [exportTargets, setExportTargets] = useState([])
    const [exportAsCorrection, setExportAsCorrection] = useState(true)

    const [canExport, setCanExport] = useState(false)
    const [hasCorrection, setHasCorrection] = useState(false)

    const [minValidFrom, setMinValidFrom] = useState("")
    const [minValidUntil, setMinValidUntil] = useState("")

    const { data, loading } = useQuery(GET_ALL_PRICING_LISTS)

    /*const [
        generatePreExport,
        { data: preExportData, loading: preExportLoading },
    ] = useLazyQuery(GET_PRE_EXPORT_REPORT)
    */

    const dispatch = useDispatch()
    
    const [triggerExport] = useMutation(EXPORT_PRICING_LISTS)

    const handelExport= () =>
    {
        triggerExport({
            variables: {
                ids: pricingLists,
                targets: exportTargets,
                exportAsCorrection,
                marketingList: marketingList?.value || null,
                allocationList:
                    allocationList?.value || null,
                minListIds: minPricingLists,
                minValidUntil: minValidUntil
                    ? new Date(minValidUntil)
                    : null,
                minValidFrom: minValidFrom
                    ? new Date(minValidFrom)
                    : null,
            },
        })
            .then(() =>
                dispatch(
                    showInfo("common:export_triggered"),
                ),
            )
            .catch((e) => dispatch(showError(e.message)))
    }

    return (
        <div>
            <Banner text={t("common:export_pricing_lists")} />
            <div className={exportStyle.container}>
                <h2>{t("common:export")}</h2>
                <div className={exportStyle.buttonSection}>
                    <input
                        type="checkbox"
                        id={outlook_export_button_id}
                        className={inputStyle.hidden}
                        onChange={(evt) =>
                            setExportTargets(
                                evt.target.checked
                                    ? ["OUTLOOK", ...exportTargets]
                                    : exportTargets.filter(
                                          (t) => t !== "OUTLOOK",
                                      ),
                            )
                        }
                        checked={exportTargets.includes("OUTLOOK")}
                    />
                    <label
                        htmlFor={outlook_export_button_id}
                        className={buttonStyle.selectButton}
                    >
                        {t(`table:distributor`)}
                    </label>

                    <input
                        type="checkbox"
                        id={pint_export_button_id}
                        className={inputStyle.hidden}
                        onChange={(evt) =>
                            setExportTargets(
                                evt.target.checked
                                    ? ["PINT", ...exportTargets]
                                    : exportTargets.filter((t) => t !== "PINT"),
                            )
                        }
                        checked={exportTargets.includes("PINT")}
                    />
                    <label
                        htmlFor={pint_export_button_id}
                        className={buttonStyle.selectButton}
                    >
                        Pint
                    </label>

                    <input
                        type="checkbox"
                        id={dst_export_button_id}
                        className={inputStyle.hidden}
                        onChange={(evt) =>
                            setExportTargets(
                                evt.target.checked
                                    ? ["DAT", ...exportTargets]
                                    : exportTargets.filter((t) => t !== "DAT"),
                            )
                        }
                        checked={exportTargets.includes("DAT")}
                    />
                    <label
                        htmlFor={dst_export_button_id}
                        className={buttonStyle.selectButton}
                    >
                        {t("table:download_prices")} (DAT)
                    </label>

                    <input
                        type="checkbox"
                        id={csv_export_button_id}
                        className={inputStyle.hidden}
                        onChange={(evt) =>
                            setExportTargets(
                                evt.target.checked
                                    ? ["CSV", ...exportTargets]
                                    : exportTargets.filter((t) => t !== "CSV"),
                            )
                        }
                        checked={exportTargets.includes("CSV")}
                    />
                    <label
                        htmlFor={csv_export_button_id}
                        className={buttonStyle.selectButton}
                    >
                        {t("table:download_prices")} (CSV)
                    </label>
                </div>
                <div className={style.headerDates}>
                    <h3>{t("table:minimum_list")}</h3>
                    <label htmlFor={min_valid_from_field}>
                        {t(`table:valid_from`)}
                    </label>
                    <input
                        type="date"
                        className={inputStyle.text}
                        id={min_valid_from_field}
                        value={minValidFrom}
                        onChange={(evt) => setMinValidFrom(evt.target.value)}
                    />
                    <label htmlFor={min_valid_until_field}>
                        {t(`table:valid_until`)}
                    </label>
                    <input
                        type="date"
                        className={inputStyle.text}
                        id={min_valid_until_field}
                        value={minValidUntil}
                        onChange={(evt) => setMinValidUntil(evt.target.value)}
                    />
                    <Display250
                        present={minPricingLists?.map(
                            (a) =>
                                data?.pricingLists?.page.find(
                                    (as) => as.id === a,
                                )?.pricingListType?.pricingListTypeNumber,
                        )}
                    />
                </div>
                <div>
                    <Select
                        isMulti={true}
                        className={style.selectMany}
                        value={minPricingLists?.map((a) => ({
                            value: a,
                            label: data?.pricingLists?.page.find(
                                (as) => as.id === a,
                            )?.name,
                        }))}
                        onChange={(as) =>
                            setMinPricingLists(as.map((a) => a.value))
                        }
                        loadingMessage={() => t("common:loading")}
                        isLoading={loading}
                        components={makeAnimated()}
                        options={data?.pricingLists?.page
                            ?.filter(
                                (p) =>
                                    p.chosenCalculation &&
                                    OPTIONS_250.includes(
                                        p?.pricingListType
                                            ?.pricingListTypeNumber,
                                    ),
                            )
                            ?.map((a) => ({
                                value: a.id,
                                label: a.name,
                            }))}
                    />
                </div>
                {(exportTargets.includes("CSV") ||
                    exportTargets.includes("PINT")) &&
                data?.allocationLists?.page?.filter((p) => p.chosenCalculation)
                    ?.length ? (
                    <>
                        <h3>{t("table:allocation_list")}</h3>
                        <div>
                            <Select
                                className={style.selectMany}
                                value={allocationList}
                                onChange={(v) =>
                                    v?.value
                                        ? setAllocationList(v)
                                        : setAllocationList(null)
                                }
                                loadingMessage={() => t("common:loading")}
                                isLoading={loading}
                                components={makeAnimated()}
                                options={[
                                    {
                                        value: "",
                                        label: "-",
                                    },
                                    ...(data?.allocationLists?.page
                                        ?.filter((p) => p.chosenCalculation)
                                        ?.map((a) => ({
                                            value: a.id,
                                            label: a.name,
                                        })) || []),
                                ]}
                            />
                        </div>
                    </>
                ) : null}
                {(exportTargets.includes("CSV") ||
                    exportTargets.includes("PINT")) &&
                data?.marketingLists?.page?.filter((p) => p.chosenCalculation)
                    ?.length ? (
                    <>
                        <h3>{t("table:marketing_list_optional")}</h3>
                        <div>
                            <Select
                                className={style.selectMany}
                                value={marketingList}
                                onChange={(v) => {
                                    if (v?.value) {
                                        setMarketingList(v)
                                        setExportAsCorrection(false)
                                    } else {
                                        setMarketingList(null)
                                    }
                                }}
                                loadingMessage={() => t("common:loading")}
                                isLoading={loading}
                                components={makeAnimated()}
                                options={[
                                    {
                                        value: "",
                                        label: "-",
                                    },
                                    ...(data?.marketingLists?.page
                                        ?.filter((p) => p.chosenCalculation)
                                        ?.map((a) => ({
                                            value: a.id,
                                            label: a.name,
                                        })) || []),
                                ]}
                            />
                        </div>
                    </>
                ) : null}
                <h2>{t("common:select_pricing_lists")}</h2>
                {hasCorrection &&
                (exportTargets.includes("CSV") ||
                    exportTargets.includes("PINT")) ? (
                    <div className={exportStyle.isCorrection}>
                        <Toggle
                            id={use_correction}
                            isChecked={exportAsCorrection}
                            setIsChecked={(c) => {
                                setExportAsCorrection(c)
                                if (c) {
                                    setMarketingList(null)
                                }
                            }}
                        />
                        <label htmlFor={use_correction}>
                            {t("table:export_as_delta")}
                        </label>
                    </div>
                ) : null}
                <div>
                    <Select
                        isMulti={true}
                        className={style.selectMany}
                        value={pricingLists?.map((a) => ({
                            value: a,
                            label: data?.pricingLists?.page.find(
                                (as) => as.id === a,
                            )?.name,
                        }))}
                        onChange={(as) => {
                            const ids = as.map((a) => a.value)
                            setPricingLists(ids)
                            setCanExport(false)
                            setHasCorrection(
                                data?.pricingLists?.page
                                    ?.filter((p) => ids.includes(p.id))
                                    .some((p) => !!p?.isCorrectionOf),
                            )
                        }}
                        loadingMessage={() => t("common:loading")}
                        isLoading={loading}
                        components={makeAnimated()}
                        options={data?.pricingLists?.page
                            ?.filter((p) => p.chosenCalculation)
                            ?.map((a) => ({
                                value: a.id,
                                label: a.name,
                            }))}
                    />
                </div>

                <button
                    className={buttonStyle.button}
                    disabled={
                        !exportTargets.length ||
                        (!pricingLists.length &&
                            !allocationList &&
                            !minPricingLists.length) ||
                        (minPricingLists.length &&
                            (!minValidUntil || !minValidFrom))
                    }
                    onClick={handelExport}>
                    {c(t("common:export"))}
                </button>

            </div>
            
        </div>
    )
}

import style from "./Expander.module.css"
import useMeasure from "react-use-measure"
import { animated, useSpring } from "react-spring"
import { Loader } from "./Loader"
import { gql, useQuery } from "@apollo/client"
import React from "react"
import { useTranslation } from "react-i18next"
import buttonStyle from "../../style/button.module.css"
import { Link } from "react-router-dom"
import { CompetitorPriceTable } from "../view/products/CompetitorPriceTable"
import {
    getCurrencyFormatter,
    getDayMonthYearFormatter,
    getIntegerFormatter,
    getNumberFormatter,
    getPercentSafeFormatter,
    getSafeFormatter,
    getTimeFormatter,
} from "../../i18n"
import {PRODUCT_EXPANDER_ENABLED} from "../../App";

/**
 * Query to get product details
 *
 * @type {DocumentNode}
 */
const PRODUCT_QUERY = gql`
    query ($id: UUID!) {
        product(id: $id) {
            id
            ifaMetric: newestProductIfaMetric {
                AEP
                GEP
                validFrom
                validUntil
                id
            }
            historicIfaMetric: newestProductHistoricIfaMetric {
                AEP
                GEP
                validFrom
                validUntil
                id
            }
            agrossoMetric: newestProductAgrossoMetric {
                agrosso1
                agrosso2
                validFrom
                validUntil
                id
            }
            performanceMetric: newestProductPerformance {
                revenueGrossoTotal
                revenueGrossoWholesale
                revenueGrossoPhoenix
                revenueGrossoDirect
                validFrom
                validUntil
                id
            }
            pricingListPerformanceMetrics: newestProductPriceListPerformances {
                salesQuantity
                revenue
                id
                validFrom
                validUntil
                pricingListType {
                    name
                    id
                }
            }
            newestCompetitorPrices {
                id
                price
                wholesaleName
                validFrom
                competitorDisplayName
                bulkPrice1
                bulkAmount1
                bulkPrice2
                bulkAmount2
                bulkPrice3
                bulkAmount3
            }
            productAggregatedMetric {
                id
                lastIfaChange
                yearlyCumulatedSalesProceeds
                yearlyCumulatedSalesVolume
                yearlyCumulatedRevenue
                yearlyCumulatedRevenueGrossoPhoenix
                yearlyCumulatedSalesProceeds
                yearlyCumulatedMarketShare
                currentInventory
                lrw
            }
        }
    }
`

/**
 *
 * @param product the product the details should be shown for
 * @param isShown the state is the expander should be rendered or not
 * @param pricingListType the pricing list type ID if available (used for filtering)
 * @param close a function that can be called to close the expander
 * @returns {JSX.Element} the expander element
 * @constructor
 */
export const ProductDetailExpander = ({
    product,
    isShown,
    pricingListType,
    close,
}) => {
    const rendered = isShown && product && PRODUCT_EXPANDER_ENABLED

    const { t, i18n } = useTranslation(["common", "table"])
    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))
    const numberFormat = getSafeFormatter(getNumberFormatter(i18n.language))
    const integerFormat = getSafeFormatter(getIntegerFormatter(i18n.language))
    const timeFormat = getSafeFormatter(getDayMonthYearFormatter(i18n.language))
    const percentFormatter = getPercentSafeFormatter(
        getNumberFormatter(i18n.language),
    )
    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    const { data, loading } = useQuery(PRODUCT_QUERY, {
        variables: {
            id: product?.id,
        },
        skip: !rendered,
    })

    const [bind, { height }] = useMeasure()

    const animatedStyle = useSpring({
        height: rendered ? `${height}px` : "0",
        minHeight: rendered ? `5em` : "0",
        border: rendered
            ? "0.25em solid var(--green)"
            : "0 solid var(--transparent)",
        config: { mass: 1, tension: 200, friction: 0, clamp: true },
    })

    return (
        <animated.div className={style.expander} style={animatedStyle}>
            <div ref={bind} className={style.expanderBody}>
                {!rendered || loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className={style.mainBox}>
                            <h1>{product?.pzn}</h1>
                            <h2>{product?.name}</h2>
                            <Link
                                to={`/product/${product?.id}`}
                                className={buttonStyle.button}
                            >
                                {t("table:show_details")}
                            </Link>
                            <button
                                onClick={close}
                                className={buttonStyle.button}
                            >
                                {t("common:close")}
                            </button>
                        </div>
                        <div>
                            <h1>{t("table:metrics")}</h1>
                            <div className={style.detailTable}>
                                <p>{t("table:aep")}</p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.ifaMetric?.AEP,
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.ifaMetric?.validFrom,
                                        ),
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.ifaMetric?.validUntil,
                                        ),
                                    )}
                                </p>
                                <p>{t("table:gep")}</p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.ifaMetric?.GEP,
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.ifaMetric?.validFrom,
                                        ),
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.ifaMetric?.validUntil,
                                        ),
                                    )}
                                </p>
                                <p>{t("table:historic_aep")}</p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.historicIfaMetric?.AEP,
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.historicIfaMetric?.validFrom,
                                        ),
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.historicIfaMetric?.validUntil,
                                        ),
                                    )}
                                </p>
                                <p>{t("table:historic_gep")}</p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.historicIfaMetric?.GEP,
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.historicIfaMetric?.validFrom,
                                        ),
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.historicIfaMetric?.validUntil,
                                        ),
                                    )}
                                </p>
                                <p>{t("table:agrosso") + " 1"}</p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.agrossoMetric?.agrosso1,
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.agrossoMetric?.validFrom,
                                        ),
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.agrossoMetric?.validUntil,
                                        ),
                                    )}
                                </p>
                                <p>{t("table:agrosso") + " 2"}</p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.agrossoMetric?.agrosso2,
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.agrossoMetric?.validFrom,
                                        ),
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.agrossoMetric?.validUntil,
                                        ),
                                    )}
                                </p>
                                {pricingListType ? (
                                    <>
                                        <p>{t("table:sales_quantity")}</p>
                                        <p>
                                            {integerFormat.format(
                                                data?.product?.pricingListPerformanceMetrics?.find(
                                                    (m) =>
                                                        m?.pricingListType
                                                            ?.id ===
                                                        pricingListType,
                                                )?.salesQuantity,
                                            )}
                                        </p>
                                        <p>
                                            {timeFormat.format(
                                                data?.product?.pricingListPerformanceMetrics?.find(
                                                    (m) =>
                                                        m?.pricingListType
                                                            ?.id ===
                                                        pricingListType,
                                                )?.validUntil,
                                            )}
                                        </p>
                                        <p>
                                            {timeFormat.format(
                                                data?.product?.pricingListPerformanceMetrics?.find(
                                                    (m) =>
                                                        m?.pricingListType
                                                            ?.id ===
                                                        pricingListType,
                                                )?.validFrom,
                                            )}
                                        </p>
                                        <p>{t("table:revenue")}</p>
                                        <p>
                                            {currencyFormat.format(
                                                data?.product?.pricingListPerformanceMetrics?.find(
                                                    (m) =>
                                                        m?.pricingListType
                                                            ?.id ===
                                                        pricingListType,
                                                )?.revenue,
                                            )}
                                        </p>
                                        <p>
                                            {timeFormat.format(
                                                data?.product?.pricingListPerformanceMetrics?.find(
                                                    (m) =>
                                                        m?.pricingListType
                                                            ?.id ===
                                                        pricingListType,
                                                )?.validUntil,
                                            )}
                                        </p>
                                        <p>
                                            {timeFormat.format(
                                                data?.product?.pricingListPerformanceMetrics?.find(
                                                    (m) =>
                                                        m?.pricingListType
                                                            ?.id ===
                                                        pricingListType,
                                                )?.validFrom,
                                            )}
                                        </p>
                                    </>
                                ) : null}
                                <p>{t("table:revenue_grosso_total")}</p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.performanceMetric
                                            ?.revenueGrossoTotal,
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.performanceMetric?.validFrom,
                                        ),
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.performanceMetric?.validUntil,
                                        ),
                                    )}
                                </p>
                                <p>{t("table:revenue_grosso_wholesale")}</p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.performanceMetric
                                            ?.revenueGrossoWholesale,
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.performanceMetric?.validFrom,
                                        ),
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.performanceMetric?.validUntil,
                                        ),
                                    )}
                                </p>
                                <p>{t("table:revenue_grosso_phoenix")}</p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.performanceMetric
                                            ?.revenueGrossoPhoenix,
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.performanceMetric?.validFrom,
                                        ),
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.performanceMetric?.validUntil,
                                        ),
                                    )}
                                </p>
                                <p>{t("table:revenue_grosso_direct")}</p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.performanceMetric
                                            ?.revenueGrossoDirect,
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.performanceMetric?.validFrom,
                                        ),
                                    )}
                                </p>
                                <p>
                                    {timeFormat.format(
                                        new Date(
                                            data?.product?.performanceMetric?.validUntil,
                                        ),
                                    )}
                                </p>

                                <p>{t("table:last_ifa_change")}</p>
                                <p>
                                    {dateFormat.format(
                                        data?.product?.productAggregatedMetric
                                            ?.lastIfaChange,
                                    )}
                                </p>
                                <p />
                                <p />
                                <p>
                                    {t("table:yearly_cumulated_sales_volume")}
                                </p>
                                <p>
                                    {integerFormat.format(
                                        data?.product?.productAggregatedMetric
                                            ?.yearlyCumulatedSalesVolume,
                                    )}
                                </p>
                                <p />
                                <p />
                                <p>
                                    {t("table:yearly_cumulated_revenue_grosso")}
                                </p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.productAggregatedMetric
                                            ?.yearlyCumulatedRevenue,
                                    )}
                                </p>
                                <p />
                                <p />
                                <p>
                                    {t("table:yearly_cumulated_sales_proceeds")}
                                </p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.productAggregatedMetric
                                            ?.yearlyCumulatedSalesProceeds,
                                    )}
                                </p>
                                <p />
                                <p />
                                <p>
                                    {t("table:yearly_cumulated_market_share")}
                                </p>
                                <p>
                                    {percentFormatter.format(
                                        data?.product?.productAggregatedMetric
                                            ?.yearlyCumulatedMarketShare,
                                    )}
                                </p>
                                <p />
                                <p />
                                <p>{t("table:current_inventory")}</p>
                                <p>
                                    {integerFormat.format(
                                        data?.product?.productAggregatedMetric
                                            ?.currentInventory,
                                    )}
                                </p>
                                <p />
                                <p />
                                <p>{t("table:lrw")}</p>
                                <p>
                                    {numberFormat.format(
                                        data?.product?.productAggregatedMetric
                                            ?.lrw,
                                    )}
                                </p>
                                <p />
                                <p />

                                {/*<p>{t("table:previous_price")}</p>*/}
                                {/*<p>{product?.previousPrice}</p>*/}
                            </div>
                        </div>

                        <div>
                            {data?.product?.newestCompetitorPrices ? (
                                <h1>{t("table:competitors")}</h1>
                            ) : null}
                            <CompetitorPriceTable
                                competitorPrices={
                                    data?.product?.newestCompetitorPrices
                                }
                            />
                        </div>
                    </>
                )}
            </div>
        </animated.div>
    )
}

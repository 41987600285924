import style from "./Expander.module.css"
import useMeasure from "react-use-measure"
import { animated, useSpring } from "react-spring"
import { Loader } from "./Loader"
import { gql, useQuery } from "@apollo/client"
import React from "react"
import { useTranslation } from "react-i18next"
import buttonStyle from "../../style/button.module.css"
import { Link } from "react-router-dom"
import {
    getCurrencyFormatter,
    getDayMonthYearFormatter,
    getIntegerFormatter,
    getNumberFormatter,
    getPercentSafeFormatter,
    getSafeFormatter,
} from "../../i18n"
import { CompetitorPriceTable } from "../view/products/CompetitorPriceTable"
import {PRODUCT_EXPANDER_ENABLED} from "../../App";

/**
 * Query to get competitor prices and aggregated metrics for a product
 *
 * @type {DocumentNode}
 */
const PRODUCT_QUERY = gql`
    query ($id: UUID!) {
        product(id: $id) {
            id
            pzn
            name
            newestCompetitorPrices {
                id
                price
                wholesaleName
                validFrom
                competitorDisplayName
                bulkPrice1
                bulkAmount1
                bulkPrice2
                bulkAmount2
                bulkPrice3
                bulkAmount3
            }
            productAggregatedMetric {
                id
                lastIfaChange
                yearlyCumulatedSalesProceeds
                yearlyCumulatedSalesVolume
                yearlyCumulatedRevenue
                yearlyCumulatedRevenueGrossoPhoenix
                yearlyCumulatedSalesProceeds
                yearlyCumulatedMarketShare
                currentInventory
                lrw
            }
        }
    }
`

/**
 *
 *
 * @param pricingListResult the pricinglist result objects details should be shown for
 * @param isShown the state is the expander should be rendered or not
 * @param close a function that can be called to close the expander
 * @returns {JSX.Element} the expander element
 * @constructor
 */
export const PricingListResultExpander = ({
    pricingListResult,
    isShown,
    close,
}) => {
    const rendered = isShown && pricingListResult && PRODUCT_EXPANDER_ENABLED

    const { t, i18n } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(PRODUCT_QUERY, {
        variables: {
            id: pricingListResult?.product?.id,
        },
        skip: !rendered,
    })

    const [bind, { height }] = useMeasure()

    const animatedStyle = useSpring({
        height: rendered ? `${height}px` : "0",
        minHeight: rendered ? `5em` : "0",
        border: rendered
            ? "0.25em solid var(--green)"
            : "0 solid var(--transparent)",
        config: { mass: 1, tension: 200, friction: 0, clamp: true },
    })

    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))
    const percentFormatter = getPercentSafeFormatter(
        getNumberFormatter(i18n.language),
    )
    const numberFormat = getSafeFormatter(getNumberFormatter(i18n.language))
    const dateFormat = getSafeFormatter(getDayMonthYearFormatter(i18n.language))
    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    return (
        <animated.div className={style.expander} style={animatedStyle}>
            <div ref={bind} className={style.expanderBody}>
                {!rendered || loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className={style.mainBox}>
                            <div>
                                <h1>{pricingListResult?.product?.pzn}</h1>
                                <h2>{pricingListResult?.product?.name}</h2>
                                <Link
                                    to={`/product/${pricingListResult?.product?.id}`}
                                    className={buttonStyle.button}
                                >
                                    {t("table:show_details")}
                                </Link>
                            </div>
                            <button
                                onClick={close}
                                className={buttonStyle.button}
                            >
                                {t("common:close")}
                            </button>
                        </div>
                        <div>
                            <h1>{t("table:metrics")}</h1>
                            <div className={style.smallDetailTable}>
                                <p>{t("table:aep")}</p>
                                <p>
                                    {currencyFormat.format(
                                        pricingListResult?.AEP,
                                    )}
                                </p>
                                <p>{t("table:export_aep")}</p>
                                <p>
                                    {currencyFormat.format(
                                        pricingListResult?.export_Aep ??
                                            pricingListResult?.AEP,
                                    )}
                                </p>
                                <p>{t("table:gep")}</p>
                                <p>
                                    {currencyFormat.format(
                                        pricingListResult?.GEP,
                                    )}
                                </p>
                                <p>{t("table:agrosso") + " 1"}</p>
                                <p>
                                    {currencyFormat.format(
                                        pricingListResult?.agrosso1,
                                    )}
                                </p>
                                <p>{t("table:agrosso") + " 2"}</p>
                                <p>
                                    {currencyFormat.format(
                                        pricingListResult?.agrosso2,
                                    )}
                                </p>
                                <p>{t("table:sales_quantity")}</p>
                                <p>
                                    {integerFormatter.format(
                                        pricingListResult?.salesQuantity,
                                    )}
                                </p>
                                <p>{t("table:revenue")}</p>
                                <p>
                                    {currencyFormat.format(
                                        pricingListResult?.revenue,
                                    )}
                                </p>
                                <p>{t("table:revenue_grosso_total")}</p>
                                <p>
                                    {currencyFormat.format(
                                        pricingListResult?.revenueGrossoTotal,
                                    )}
                                </p>
                                <p>{t("table:revenue_grosso_wholesale")}</p>
                                <p>
                                    {currencyFormat.format(
                                        pricingListResult?.revenueGrossoWholesale,
                                    )}
                                </p>
                                <p>{t("table:revenue_grosso_phoenix")}</p>
                                <p>
                                    {currencyFormat.format(
                                        pricingListResult?.revenueGrossoPhoenix,
                                    )}
                                </p>
                                <p>{t("table:revenue_grosso_direct")}</p>
                                <p>
                                    {currencyFormat.format(
                                        pricingListResult?.revenueGrossoDirect,
                                    )}
                                </p>
                                <p>{t("table:previous_price")}</p>
                                <p>
                                    {currencyFormat.format(
                                        pricingListResult?.previousPrice,
                                    )}
                                </p>

                                <p>{t("table:last_ifa_change")}</p>
                                <p>
                                    {dateFormat.format(
                                        new Date(
                                            data?.product?.productAggregatedMetric?.lastIfaChange,
                                        ),
                                    )}
                                </p>
                                <p>
                                    {t("table:yearly_cumulated_sales_volume")}
                                </p>
                                <p>
                                    {integerFormatter.format(
                                        data?.product?.productAggregatedMetric
                                            ?.yearlyCumulatedSalesVolume,
                                    )}
                                </p>
                                <p>
                                    {t("table:yearly_cumulated_revenue_grosso")}
                                </p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.productAggregatedMetric
                                            ?.yearlyCumulatedRevenue,
                                    )}
                                </p>
                                <p>
                                    {t("table:yearly_cumulated_sales_proceeds")}
                                </p>
                                <p>
                                    {currencyFormat.format(
                                        data?.product?.productAggregatedMetric
                                            ?.yearlyCumulatedSalesProceeds,
                                    )}
                                </p>
                                <p>
                                    {t("table:yearly_cumulated_market_share")}
                                </p>
                                <p>
                                    {percentFormatter.format(
                                        data?.product?.productAggregatedMetric
                                            ?.yearlyCumulatedMarketShare,
                                    )}
                                </p>
                                <p>{t("table:current_inventory")}</p>
                                <p>
                                    {integerFormatter.format(
                                        data?.product?.productAggregatedMetric
                                            ?.currentInventory,
                                    )}
                                </p>
                                <p>{t("table:lrw")}</p>
                                <p>
                                    {numberFormat.format(
                                        data?.product?.productAggregatedMetric
                                            ?.lrw,
                                    )}
                                </p>
                            </div>
                        </div>
                        <div>
                            {data?.product?.newestCompetitorPrices ? (
                                <h1>{t("table:competitors")}</h1>
                            ) : null}
                            <CompetitorPriceTable
                                competitorPrices={
                                    data?.product?.newestCompetitorPrices
                                }
                            />
                        </div>
                    </>
                )}
            </div>
        </animated.div>
    )
}
